import { requestConstantsMaker } from '../helper';

/**** 20200217 ****/
export const GET_ALL_APPLICATION = requestConstantsMaker('GET_ALL_APPLICATION');
export const GET_APPLICATION = requestConstantsMaker('GET_APPLICATION');
export const GET_APPLICATION_FOR_ACCEPT_APPLICATION = requestConstantsMaker('GET_APPLICATION_FOR_ACCEPT_APPLICATION');
export const CREATE_APPLICATION = requestConstantsMaker('CREATE_APPLICATION');
export const UPDATE_APPLICATION = requestConstantsMaker('UPDATE_APPLICATION');
export const GET_APPLICATIONS_FOR_SELECTION = requestConstantsMaker('GET_APPLICATIONS_FOR_SELECTION');
export const GET_APPLICATIONS_FOR_STUDENT_DETAIL = requestConstantsMaker('GET_APPLICATIONS_FOR_STUDENT_DETAIL');
export const GET_NEW_APPLICATIONS = requestConstantsMaker('GET_NEW_APPLICATIONS');
export const GET_WAITING_APPLICATIONS = requestConstantsMaker('GET_WAITING_APPLICATIONS');
export const RESERVE_APPLICATION = requestConstantsMaker('RESERVE_APPLICATION');
export const RESTORE_APPLICATION = requestConstantsMaker('RESTORE_APPLICATION');
export const GET_COURSE_SCHEDULE = requestConstantsMaker('GET_COURSE_SCHEDULE');
export const GET_SUMMER_COURSE_SCHEDULE = requestConstantsMaker('GET_SUMMER_COURSE_SCHEDULE');
export const GET_ALL_SUMMER_COURSE_SCHEDULE = requestConstantsMaker('GET_ALL_SUMMER_COURSE_SCHEDULE');
export const GET_CHECK_SESSION_CAPACITY = requestConstantsMaker('GET_CHECK_SESSION_CAPACITY');
/**** 20200217 ****/

export const GET_ALL_APPLICATION_BY_SPECIFICATION = requestConstantsMaker('GET_ALL_APPLICATION_BY_SPECIFICATION');
export const GET_ALL_APPLICATION_FOR_CREATE_INVOICE = requestConstantsMaker('GET_ALL_APPLICATION_FOR_CREATE_INVOICE');
export const GET_ALL_APPLICATION_SESSION_BY_SPECIFICATION = requestConstantsMaker('GET_ALL_APPLICATION_SESSION_BY_SPECIFICATION');
export const GET_ALL_WAITING_APPLICATION_BY_CLASS_ID = requestConstantsMaker('GET_ALL_WAITING_APPLICATION_BY_CLASS_ID');
export const GET_APPLICATION_BY_ID = requestConstantsMaker('GET_APPLICATION_BY_ID');
export const POST_CREATE_APPLICATION = requestConstantsMaker('POST_CREATE_APPLICATION');
export const POST_CHECK_SESSION_CAPACITY = requestConstantsMaker('POST_CHECK_SESSION_CAPACITY');
export const PUT_UPDATE_APPLICATION = requestConstantsMaker('PUT_UPDATE_APPLICATION');
export const DELETE_APPLICATION = requestConstantsMaker('DELETE_APPLICATION');
// export const GET_APPLICATIONS_FOR_STUDENT_DETAIL = requestConstantsMaker('GET_APPLICATIONS_FOR_STUDENT_DETAIL');
